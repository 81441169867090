import {isMobile} from '../../../commons/selectors/environment'
import {MemberPageContext, Routes} from '../../types/state'
import {connect} from '../connect'
import {AppContainer as AppContainerPresentation} from './app-container'

export interface AppContainerProps extends AppContainerRuntimeProps, AppContainerOwnProps {}

interface AppContainerRuntimeProps {
  mobile: boolean
  route: Routes
}

interface AppContainerOwnProps {
  isRTL: boolean
  resize: () => void
}

const mapRuntime = ({state}: MemberPageContext): AppContainerRuntimeProps => ({
  mobile: isMobile(state),
  route: state.navigation.route
})

export const AppContainer = connect<AppContainerOwnProps, AppContainerRuntimeProps>(mapRuntime)(
  AppContainerPresentation
)
