import Wix, {getInstance as getWixInstance} from '@wix/wix-events-commons-statics/dist/polyfills/wix-sdk'
import {IWixColorParam, IWixStyleFont} from 'native-components-infra/dist/es/src/types/wix-sdk'
import {ISiteMapPageLink, IWixColor} from 'native-components-infra/dist/src/types/wix-sdk'
import {ColorPickerResponse, EditorFontPickerConfig, EditorFontPickerResponse} from '../types/sdk-types'

export const setHeight = (height: number) => Wix.setHeight(height)

export const resizeComponent = (options: {width?: number; height?: number}): Promise<void> =>
  new Promise((resolve, reject) => {
    Wix.resizeComponent(options, resolve, reject)
  })

export const getSdkData = (
  key: string,
  options: {scope: 'APP' | 'COMPONENT'},
  onSuccess: Function,
  onFailure?: Function
) => Wix.Data.Public.get(key, options, onSuccess, onFailure)

export const removeSdkData = (
  key: string,
  options: {scope: 'APP' | 'COMPONENT'},
  onSuccess?: Function,
  onFailure?: Function
) => Wix.Data.Public.remove(key, options, onSuccess, onFailure)

export const getLocale = () => Wix.Utils.getLocale()

export const closeWindow = (options?: any) => Wix.closeWindow(options)

export const addEventListener = (event: string, callback: Function) => Wix.addEventListener(event, callback)

export const getStyleParams = (callback?: Function) => Wix.Styles.getStyleParams(callback)

export const getSiteColors = () => Wix.Styles.getSiteColors()

export const setFontParam = (key: string, font: IWixStyleFont) => Wix.Styles.setFontParam(key, {value: font})

export const setColorParam = (key: string, color: IWixColorParam) => Wix.Styles.setColorParam(key, {value: color})

export const setNumberParam = (key: string, number: number) => Wix.Styles.setNumberParam(key, {value: number})

export const setUIParam = (domain: string, key: string, value: any) => Wix.Styles.setUILIBParamValue(domain, key, value)

export const getSiteTextPresets = () => Wix.Styles.getSiteTextPresets()

export const openFontPicker = (options: EditorFontPickerConfig, onChange: (res: EditorFontPickerResponse) => void) =>
  Wix.Styles.openFontPicker(options, onChange)

export const openColorPicker = (options, onChange: (res: ColorPickerResponse) => void) =>
  Wix.Styles.openColorPicker(options, onChange)

export const getColorByReference = (reference: string): string =>
  (Wix.Styles.getColorByreference(reference) as IWixColor).value

export const getInstance = () => getWixInstance()

export const getViewMode = () => Wix.Utils.getViewMode()

export const isMobile = () => Wix.Utils.getDeviceType() === 'mobile'

export const getCompId = () => Wix.Utils.getCompId()

export const getOrigCompId = () => Wix.Utils.getOrigCompId()

export const triggerSettingsUpdated = (type: string, compId: string, payload?: object) => {
  Wix.Settings.triggerSettingsUpdatedEvent({type, ...payload}, compId)
}

export const getSiteMap = (callback: (siteMap: ISiteMapPageLink[]) => void) => {
  Wix.getSiteMap(callback)
}

export const navigateToPage = (pageId: string) => {
  Wix.navigateToPage(pageId)
}

export const Events = Wix.Events
