import * as React from 'react'
import {ALIGNMENT, SKIN, VARIANT} from 'wix-ui-tpa/Tabs'
import {EventTabsProps} from '.'
import {EVENT_LIST_TABS} from '../../config/data-hooks'
import {Tabs} from '../../types/state'
import {Tabs as TabsComp} from '../styled-components/tabs'

export const EventsTabs = ({
  tab,
  setTab,
  getEvents,
  upcomingEventsTabLabel,
  pastEventsTabLabel,
  mobile
}: EventTabsProps) => {
  const onTabClick = (newTab: Tabs) => {
    setTab(newTab)
    getEvents()
  }

  return (
    <TabsComp
      data-hook={EVENT_LIST_TABS}
      items={[{title: upcomingEventsTabLabel}, {title: pastEventsTabLabel}]}
      onTabClick={onTabClick}
      alignment={mobile ? ALIGNMENT.center : ALIGNMENT.left}
      variant={mobile ? VARIANT.fullWidth : VARIANT.fit}
      skin={mobile ? SKIN.fullUnderline : SKIN.clear}
      activeTabIndex={tab}
      mobile={mobile}
    />
  )
}
