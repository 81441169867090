import {withNamespaces, WithNamespaces} from 'react-i18next'
import {downloadTickets} from '../../../services/tickets'
import {connect} from '../../connect'
import {MobileDownloadTicketsButton as DownloadTicketsButtonPresentation} from './mobile-download-tickets-button'

export interface MobileDownloadTicketsButtonProps
  extends MobileDownloadTicketsButtonOwnProps,
    MobileDownloadTicketsButtonRuntimeProps,
    WithNamespaces {}

interface MobileDownloadTicketsButtonOwnProps {
  order: wix.events.ticketing.Order
}

interface MobileDownloadTicketsButtonRuntimeProps {
  downloadTickets: typeof downloadTickets
}

const mapRuntime = () => ({
  downloadTickets
})

export const MobileDownloadTicketsButton = connect<
  MobileDownloadTicketsButtonOwnProps,
  MobileDownloadTicketsButtonRuntimeProps
>(mapRuntime)(withNamespaces()(DownloadTicketsButtonPresentation))
