import * as DateFormatter from '@wix/wix-events-commons-statics/dist/date/formatter'
import {getFormattedMoney} from '@wix/wix-events-commons-statics/dist/price/formatter'
import * as classNames from 'classnames'
import * as React from 'react'
import {
  DISCOUNT_TOTAL,
  MOBILE_ORDER_DETAILS_NUMBER,
  MOBILE_ORDER_DETAILS_TOTAL,
  TICKET,
  TICKET_TOTAL
} from '../../config/data-hooks'
import {getFormattedOrderTotal} from '../../selectors/event'
import {Routes} from '../../types/state'
import {MobileDownloadTicketsButton} from '../actions/mobile-download-tickets-button'
import {Back} from '../back'
import {Divider} from '../styled-components/divider'
import {EventText} from '../styled-components/event-text'
import {MobileOrderProps} from './'
import * as s from './mobile-order.scss'

export class MobileOrder extends React.Component<MobileOrderProps> {
  render() {
    const {order, locale, timezone, internalNavigate, event, t} = this.props
    const {
      orderNumber,
      created,
      invoice: {items, discount}
    } = order
    const total = getFormattedOrderTotal(order)
    return (
      <div className={s.root}>
        <Back onClick={() => internalNavigate(Routes.ORDER_LIST, {event})} t={t} />
        <div className={s.section}>
          <div className={s.title}>
            <EventText extraClass={s.title}>{t('mobile.order.details.title')}</EventText>
          </div>
          <div className={s.orderNumber} data-hook={MOBILE_ORDER_DETAILS_NUMBER}>
            <EventText faded extraClass={s.text}>
              {t('mobile.order.number', {orderNumber})}
            </EventText>
          </div>
          <div className={s.date}>
            <EventText faded extraClass={s.text}>
              {t('mobile.order.date', {date: DateFormatter.getYearMonthDay(created, timezone, locale)})}
            </EventText>
          </div>
          <div className={s.total} data-hook={MOBILE_ORDER_DETAILS_TOTAL}>
            <EventText extraClass={s.text}>{t('mobile.order.total', {total})}</EventText>
          </div>
          <div className={classNames(s.button, s.largeText)}>
            <MobileDownloadTicketsButton order={order} />
          </div>
        </div>
        <Divider />
        {items.map(item => (
          <Ticket item={item} t={t} key={item.id} />
        ))}
        {discount ? <Discount discount={discount} t={t} /> : null}
        <div className={classNames(s.overallTotal, s.splitRow)}>
          <EventText extraClass={s.largeText}>{t('mobile.order.overall.total')}</EventText>
          <EventText extraClass={s.largeText}>{total}</EventText>
        </div>
      </div>
    )
  }
}

const Ticket = ({item, t}) => {
  const {name, price, quantity, total} = item
  return (
    <>
      <div className={s.section} data-hook={TICKET}>
        <div>
          <EventText extraClass={s.text}>{name}</EventText>
        </div>
        <div className={classNames(s.price, s.splitRow)}>
          <EventText faded extraClass={s.text}>
            {t('mobile.order.ticket.price')}
          </EventText>
          <EventText faded extraClass={s.text}>
            {getFormattedMoney(price)}
          </EventText>
        </div>
        <div className={classNames(s.quantity, s.splitRow)}>
          <EventText faded extraClass={s.text}>
            {t('mobile.order.ticket.quantity')}
          </EventText>
          <EventText faded extraClass={s.text}>
            {quantity}
          </EventText>
        </div>
        <div className={classNames(s.ticketTotal, s.splitRow)} data-hook={TICKET_TOTAL}>
          <EventText extraClass={s.text}>{t('mobile.order.ticket.total')}</EventText>
          <EventText extraClass={s.text}>{getFormattedMoney(total)}</EventText>
        </div>
      </div>
      <Divider />
    </>
  )
}

const Discount = ({discount, t}) => (
  <>
    <div className={s.section}>
      <div className={s.splitRow} data-hook={DISCOUNT_TOTAL}>
        <EventText extraClass={s.text}>{t('mobile.order.coupon')}</EventText>
        <EventText extraClass={s.text}>{`-${getFormattedMoney(discount.amount)}`}</EventText>
      </div>
    </div>
    <Divider />
  </>
)
