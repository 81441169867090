import * as React from 'react'
import {MOBILE_BUTTON_DOWNLOAD_TICKETS} from '../../../config/data-hooks'
import {EventLink} from '../../styled-components/event-link'
import {MobileDownloadTicketsButtonProps} from './index'

export const MobileDownloadTicketsButton = ({downloadTickets, order, t}: MobileDownloadTicketsButtonProps) => (
  <EventLink primary mobile={true} onClick={() => downloadTickets(order)} data-hook={MOBILE_BUTTON_DOWNLOAD_TICKETS}>
    {t('events.buttons.download-tickets')}
  </EventLink>
)
